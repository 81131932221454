import { Component } from '@angular/core';

@Component({
  selector: 'app-office-management-staff',
  templateUrl: './office-management-staff.component.html',
  styleUrls: ['./office-management-staff.component.scss']
})
export class OfficeManagementStaffComponent {

}
