<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Office Management Staff</h1>
                    <p>The Office/Facility Management Staff are responsible for providing secretarial, clerical and 
                        administrative support in order to ensure that all services are provided in an effective and 
                        efficient manner. We believe that first impression of a customer occurs at front desk - 
                        the place where they receive their first taste of service. Also, the facility management 
                        shows to the culture of any organisation. We provide Office / Facility Management Staff, who 
                        are cheerful, well groomed, and articulate with appropriate attire. Our staffs possess time 
                        management discipline and understanding of hospitality systems. We have huge database of qualified 
                        Office / Facility Management Staff having a good amount experience across all industries 
                        and can efficiently meet the requirements of our clients.</p>
                    <p><strong>We provide following Office / Facility Management Staff across various industries:</strong></p>
                    <ul>
                        <li>Front Office Executive / Receptionist</li>
                        <li>Back Office Executive</li>
                        <li>Data Entry Operator</li>
                        <li>Typist / Stenographer</li>
                        <li>Tele Operator</li>
                        <li>Office Assistant</li>
                        <li>Time Office Staff</li>
                        <li>Chauffeur</li>
                        <li>Office Boy</li>
                        <li>Pantry Staff</li>
                        <li>Waiter</li>
                        <li>Caretaker</li>
                        <li>Cook & Mess Helper</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>