<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Telecommunication</h1>
                    <p>At present, the <strong>Telecom</strong> Industry of India is flourishing. It is a fact that,home to 
                        the maximum number of mobile users. Telecommunications now also includes the use of 
                        electrical devices such as telegraphs, telephones, and teletypes, radio and microwave 
                        communications as well as fiber optics and their associated electronics along with the 
                        use of the orbiting satellites and the Internet. Many national corporate giants and 
                        international companies have ventured into this sector to explore the benefits that are 
                        associated with the growing demands of Indian consumers.</p>
                    <p> <strong>JJR Consultancyhas</strong> a large databank of suitable candidate profiles for different 
                        job roles in telecom Sector as well as in internet service provider companies. 
                        We undertake hiring for all job positions including engineers, technicians, 
                        sales representatives, tele operators, etc.</p>
                </div>
            </div>
        </div>
    </div>
</div>