<!-- Start Footer Area -->
<footer
    class="footer-area"
    [ngClass]="{'d-none': router.url === '/demo-7' || router.url === '/personal' || router.url === '/business' || router.url === '/how-it-works' || router.url === '/about-us-2' || router.url === '/contact-2'}"
>
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo_jjr.png" alt="logo"></a>
                        <p>Quis ipsum suspendisse ultrices gravida commodo. Risus commodo veliliee vel viverra maecenas accumsan lacus vel facilisis.</p>
                    </div>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/jjrconsultancy" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://twitter.com/JJRConsultancy" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="https://plus.google.com/b/115341575456037572933/+Jjrconsultancy/about" target="_blank"><i class="fab fa-google"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/jjr-consultancy-private-limited" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pr-5">
                    <h3 class="widget-title"><span style="color: #027CD5;">Q</span>uick Links</h3>
                    <ul class="list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/company-profile">Company Profile</a></li>
                        <li><a routerLink="/management-profile">Management Profile</a></li>
                        <li><a routerLink="/vision">Vision</a></li>
                        <li><a routerLink="/mission">Mission</a></li>
                        <li><a routerLink="/careers">Careers@JJR</a></li>
                        <li><a routerLink="/clients">Clients</a></li>
                        <li><a routerLink="/candidates">Candidates</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pr-5">
                    <h3 class="widget-title"><span style="color: #027CD5;">O</span>ur Services</h3>

                    <ul class="list">
                        <li><a routerLink="/leadership-hiring">Leadership Hiring</a></li>
                        <li><a routerLink="/executive-recruitment">Recruitment of Engineers & Executives</a></li>
                        <li><a routerLink="/security-service">Security Service</a></li>
                        <li><a routerLink="/labour-employment">Recruitment of Workmen</a></li>
                        <li><a routerLink="/labour-contractor">Labour Contractor</a></li>
                        <li><a routerLink="/office-management-staff">Office/Facility Management Staff</a></li>
                        <li><a routerLink="/house-keeping-staff">House Keeping Staff</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3 class="widget-title"><span style="color: #027CD5;">I</span>ndustries We Serve</h3>

                    <ul class="list">
                        <li><a routerLink="/infrastructure">Infrastructure</a></li>
                        <li><a routerLink="/manufacturing">Manufacturing</a></li>
                        <li><a routerLink="/power-steel">Power & Steel</a></li>
                        <li><a routerLink="/fmcg">FMCG</a></li>
                        <li><a routerLink="/pharmaceutical-healthcare">Pharmaceutical & Healthcare</a></li>
                        <li><a routerLink="/information-technology">Information Technology</a></li>
                        <li><a routerLink="/it-bpo">ITes & BPO</a></li>
                        <li><a routerLink="/telecom">Telecom</a></li>
                        <li><a routerLink="/banking-financial-services">Banking & Financial Services</a></li>
                        <li><a routerLink="/education">Education</a></li>
                        <li><a routerLink="/retail-hospitality">Retail & Hospitality</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3 class="widget-title"><span style="color: #027CD5; " >G</span>et in touch</h3>

                    <ul class="footer-contact-info">
                        <li><span>Location:</span> <br> JJR Consultancy Pvt Ltd. <br> 
                            107, Jaganath Complex, Block - A, Nandan Vihar, 
                            Kalarahanga, Patia, Bhubaneswar - 751024, Odisha, India
                        </li>
                        <li><span>Email:</span> <a href="mailto:info@jjrconsultancy.com">info@jjrconsultancy.com</a></li>
                        <li><span>Phone:</span> <a href="tel:+91-9090919990"> +91-9090919990</a></li>

                        <img src="assets/img/iso-logo.jpg" width="305" height="80">
                        <br>
                        <div class="single-footer-widget">
                            <div class="logo">
                                <!-- <a routerLink="/"><img src="assets/img/logo_jjr.png" alt="logo"></a> -->
                                <p>Follow Us on:</p>
                            </div>
                            <ul class="social-links">
                                <li><a href="https://www.facebook.com/jjrconsultancy" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://twitter.com/JJRConsultancy" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://plus.google.com/b/115341575456037572933/+Jjrconsultancy/about" target="_blank"><i class="fab fa-google"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/jjr-consultancy-private-limited" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                        <!-- <li><span>Fax:</span> <a href="tel:+12129876543">+1-212-9876543</a></li> -->
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>Copyright @ 2024 JJR Consultancy Pvt Ltd. All Rights Reserved | Designed by <a href="https://lyient.com/" target="_blank">Lyient Solutions</a></p>
        </div>
    </div>
    <div class="map-image"><img src="assets/img/map.png" alt="map"></div>
</footer>

<footer
    class="ctp-footer-area pt-100 d-none"
    [ngClass]="{'d-block': router.url === '/demo-7' || router.url === '/personal' || router.url === '/business' || router.url === '/how-it-works' || router.url === '/about-us-2' || router.url === '/contact-2'}"
>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo_jjr.png" alt="logo">
                        </a>
                    </div>
                    <ul class="social-links">
                        <span>Find us on social media</span>
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Company</h3>
                    <ul class="links">
                        <li>
                            <a routerLink="/about-two">About us</a>
                        </li>
                        <li>
                            <a routerLink="/how-it-works">How it works</a>
                        </li>
                        <li>
                            <a routerLink="/">Mobile apps</a>
                        </li>
                        <li>
                            <a routerLink="/">Reviews</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Support</h3>
                    <ul class="links">
                        <li>
                            <a routerLink="/">Terms and conditions</a>
                        </li>
                        <li>
                            <a routerLink="/">Privacy policy</a>
                        </li>
                        <li>
                            <a routerLink="/contact-two">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Need help?</h3>
                    <ul class="info">
                        <li>
                            <span>Location: </span>
                            27 Division St, New York, NY 10002, USA
                        </li>
                        <li>
                            <span>Email: </span>
                            <a href="mailto:klob@gmail.com">klob@gmail.com</a>
                        </li>
                        <li>
                            <span>Email: </span>
                            <a href="tel:321984754">+ (321) 984 754</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>© Klob is proudly created by <a href="https://envytheme.com/" target="_blank"> EnvyTheme</a></p>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i></div>