<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Recruitment of Workmen</h1>
                    <p>Companies in infrastructure, manufacturing and other sectors find difficulty in getting 
                        critical category of workmen as per their requirement. <strong>JJR Consultancy</strong> has a 
                        huge database of highly-skilled, skilled, semi-skilled and unskilled workmen to 
                        meet the demands of our clients in <strong>India & Overseas</strong>.</p>
                    <p><strong>We provide recruitment service to our clients in the following categories of workmen:</strong></p>
                    <ul>
                        <li>Foremen / Supervisors</li>
                        <li>Fitters / Barbenders</li>
                        <li>Crane Operators</li>
                        <li>Plant and Equipment Operators</li>
                        <li>Mechanics</li>
                        <li>Electricians</li>
                        <li>Technicians</li>
                        <li>Welders / Gas Cutters</li>
                        <li>Masons</li>
                        <li>Grinders</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>