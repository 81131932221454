import { Component } from '@angular/core';

@Component({
  selector: 'app-house-keeping-staff',
  templateUrl: './house-keeping-staff.component.html',
  styleUrls: ['./house-keeping-staff.component.scss']
})
export class HouseKeepingStaffComponent {

}
