<!-- Start Navbar Area -->
<div class="navbar-area {{layoutClass}}">
    <div class="klob-nav">
        <div
            class="container"
            [ngClass]="{'container-fluid': router.url === '/demo-7'}"
        >
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo_jjr.png" alt="logo">
                    <img src="assets/img/logo_jjr.png" alt="logo">
                    <h1 style="background-color: red; color:white; font-size: 11px; font-family: Comic Sans MS;">
                        <strong> An ISO 9001 : 2008 Certified Company</strong>  </h1>
                </a>
                
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Home</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">About Us <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/company-profile" routerLinkActive="active"  class="nav-link">Company Profile</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/management-profile" routerLinkActive="active" class="nav-link">Management Profile</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/vision" routerLinkActive="active" class="nav-link">Vision</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/mission" routerLinkActive="active" class="nav-link">Mission</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Our Services <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/leadership-hiring" routerLinkActive="active" class="nav-link">Leadership Hiring</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/executive-recruitment" routerLinkActive="active" class="nav-link">Recruitment of Engineers & Executives</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/security-service" routerLinkActive="active" class="nav-link">Security Service</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/labour-employment" routerLinkActive="active" class="nav-link">Recruitment of Workmen</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/labour-contractor" routerLinkActive="active" class="nav-link">Labour Contractor</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/office-management-staff" routerLinkActive="active" class="nav-link">Office/Facility Management Staff</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/house-keeping-staff" routerLinkActive="active" class="nav-link">House Keeping Staff</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Industries We Serve <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/infrastructure" routerLinkActive="active"  class="nav-link">Infrastructure</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/manufacturing" routerLinkActive="active" class="nav-link">Manufacturing</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/power-steel" routerLinkActive="active" class="nav-link">Power & Steel</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/fmcg" routerLinkActive="active" class="nav-link">FMCG</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/pharmaceutical-healthcare" routerLinkActive="active" class="nav-link">Pharmaceutical & Healthcare</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/information-technology" routerLinkActive="active" class="nav-link">Information Technology</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/it-bpo" routerLinkActive="active" class="nav-link">ITes & BPO</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/telecom" routerLinkActive="active" class="nav-link">Telecom</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/banking-financial-services" routerLinkActive="active" class="nav-link">Banking & Financial Services</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/education" routerLinkActive="active" class="nav-link">Education</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/retail-hospitality" routerLinkActive="active" class="nav-link">Retail & Hospitality</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a routerLink="/demo-5" routerLinkActive="active" class="nav-link">Bankers & Entrepreneurs</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-6" routerLinkActive="active" class="nav-link">Social Finance Agency</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-7" routerLinkActive="active" class="nav-link">Currency Transfer Provider</a>
                                </li> -->
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/careers" routerLinkActive="active" class="nav-link">CAREERS@JJR</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a routerLink="/features" routerLinkActive="active" class="nav-link">Features</a>
                        </li> -->

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">
                                Contact Us <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/clients" routerLinkActive="active" class="nav-link">Client</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/candidates" routerLinkActive="active" class="nav-link">Candidate</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a routerLink="/business" routerLinkActive="active" class="nav-link">Business</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/how-it-works" routerLinkActive="active" class="nav-link">How It Works</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/error-404" routerLinkActive="active" class="nav-link">404 Error Page</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/signup" routerLinkActive="active" class="nav-link">Signup</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/signin" routerLinkActive="active" class="nav-link">Signin</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/contact-2" routerLinkActive="active" class="nav-link">Contact Us Two</a>
                                </li> -->
                            </ul>
                        </li>
                        <!-- <li class="nav-item">
                            <a routerLink="/pricing" routerLinkActive="active" class="nav-link">Pricing</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Blog <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/blog-right-sidebar" routerLinkActive="active" class="nav-link">Blog Right Sidebar</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/blog-details" routerLinkActive="active" class="nav-link">Blog Details</a>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li> -->
                    </ul>
                    <!-- <div class="others-options">
                        <a routerLink="/signin" routerLinkActive="active" class="login-btn"><i class="flaticon-user"></i> Log In</a>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->