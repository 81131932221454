<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Housekeeping Staff</h1>
                    <p>We are one of the most trustworthy options, when it comes to availing professional home 
                        and office housekeeping services across industries. Our endeavour is to provide our 
                        clients with trained Housekeeping Staff and what they desire in terms of performance. 
                        Our evaluation team ensures that the standard procedures are followed, which is widely 
                        appreciated among our clients across the country.</p>
                    <p><strong>Our Features:</strong></p>
                    <ul>
                        <li>Trained Staff</li>
                        <li>Continuous Evaluation</li>
                        <li>Maintain high levels of performance</li>
                    </ul>
                    <p>We provide following <strong>Housekeeping Staffs</strong> to Corporate Houses, Manufacturing & Production Plants, 
                        Construction Sites, Banks, Shopping Malls, Hotels, Multiplexes, Hospitals, Educational Institutes, 
                        Housing Societies and Apartments:Our Features:</p>
                    <ul>
                        <li>Sweepers</li>
                        <li>Maids</li>
                        <li>Cleaning Staffs</li>
                        <li>Gardeners</li>
                        <li>Janitors</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>