<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <!-- <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1> -->
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="information-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-information-box">
                    <!-- <div class="icon">
                        <i class="flaticon-piggy-bank"></i>
                    </div>
                    <h3>$0.00 / mo</h3> -->
                    <h1 style="color:black; font-size: 50px; text-align: center;">Manufacturing </h1>
                    <p><strong>Manufacturing</strong> is the base of any organization. It is impossible to function without 
                        a dedicated manufacturing / production division for sectors such as FMCG, automobile, 
                        textile, electronic goods, etc. The escalating growth in the industrial sector has 
                        led to a large number of job openings and requirement of experienced as well as skilled workforce.</p>
                    <p><strong>JJR Consultancy</strong> provides recruitment support to some of the leading manufacturing 
                        companies in India. The manufacturing industry requires candidates having technical knowledge 
                        and experience in the required field. We undertake hiring for junior to senior level positions 
                        in the manufacturing division as per the requirements of the clients in the minimum 
                        possible time. Accordingly, we have developed a large databank of candidate profiles 
                        with relevant skills, qualifications, and experience for different jobs in the Manufacturing 
                        Division along with that of skilled, semiskilled and unskilled labour.</p>
                </div>
            </div>
        </div>
    </div>
</div>