<!-- Start Main Banner Area -->
<div class="main-banner jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1 style="color:black; font-size: 50px;">Housekeeping Staff</h1>
                    <!-- <h1>Easy, fee-free banking for entrepreneurs</h1>
                    <p>Get the financial tools and insights to start, build, and grow your business.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Featured Boxes Area -->
<div class="featured-boxes-area">
    <div class="container">
        <div class="featured-boxes-inner">
            <div class="row m-0">
                <div class="col-lg-12 col-sm-12 col-md-12 p-0">
                    <div class="single-featured-box">
                        <!-- <div class="icon color-fb7756">
                            <i class="flaticon-piggy-bank"></i>
                        </div> -->
                        <h2>Welcome To <span style="color: #027CD5;">JJR Consultancy </span></h2>
                        <br>
                        <p style="font-size: 20px; color:black; text-align: justify;">We provide services to all sectors with excellent quality professionals, workers and other staffing solutions. 
                            We believe that building and maintaining strong relationship is an integral part of developing a business 
                            and ensuring its long term success. We fulfill the staffing requirements for businesses ranging from 
                            multinationals to small enterprises. As an organization, we work as a Recruitment Consultant with 
                            candidates and as a Recruitment Partner with clients. The needs of our clients are often diverse 
                            and jobs are of wide range. Our aim is to provide value added services to Clients & Candidates.</p>
                        <a routerLink="/company-profile" class="btn btn-primary" >Read More</a>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <i class="flaticon-data-encryption"></i>
                        </div>
                        <h3>Fully Encrypted</h3>
                        <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon color-1ac0c6">
                            <i class="flaticon-wallet"></i>
                        </div>
                        <h3>Instant Cashout</h3>
                        <p>Lorem ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6 p-0">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-shield"></i>
                        </div>
                        <h3>Safe and Secure</h3>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                        <a routerLink="/features" class="read-more-btn">Read More</a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Featured Boxes Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Freelancers, entrepreneurs, and sole traders</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Free plan available</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Full data privacy compliance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Commitment-free</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Real-time spending overview</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Debit Mastercard included</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Small- to medium-sized businesses</h2>
                    <div class="bar"></div>
                    <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Large or enterprise level businesses</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Corporate Cards</span></li>
                        <li><span><i class="flaticon-check-mark"></i> International Payments</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Automated accounting</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Request Features</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Premium Support</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Direct Debit</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Comparisons Area -->
<!-- <div class="comparisons-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Compare us with others</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="comparisons-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Packages</th>
                        <th scope="col">Freelancer</th>
                        <th scope="col">Householder</th>
                        <th scope="col">Business Man</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Control payout timing</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Transparent payouts</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Automate evidence submission</td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>Collaboration notes</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                    </tr>
                    <tr>
                        <td>Deposit tagging</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-cancel"></i></td>
                    </tr>
                    <tr>
                        <td>Technical support over IRC</td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                    <tr>
                        <td>24×7 support</td>
                        <td><i class="flaticon-cancel"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                        <td><i class="flaticon-check-mark"></i></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div> -->
<!-- End Comparisons Area -->

<!-- Start Industry We Serve Area -->
<div class="features-area ptb-70 bg-f6f4f8">
    <div class="container" style = "background-image: url(assets/img/course-bg.jpg);
    background-repeat: no-repeat; background-size: cover; padding: 100px 0px; background-position: 50% 134.8px;">
        <div class="section-title">
            <h2><span style="color: #027CD5;">Industries</span> We Serve</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon">
                        <img src="assets/img/homepage_icon_img/Training.png" alt="Training">
                    </div> -->
                    <h3> Infrastructure </h3>
                    <p> The USD 5 Billon Infrastructure industry in India is expected to reach USD .... 
                        <a routerLink="/infrastructure" class="read-more-btn">Read More</a>
                    </p>
                    
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon bg-f78acb">
                        <img src="assets/img/homepage_icon_img/Learning-Infrastructure.png" alt="Recruitment">
                    </div> -->
                    <h3> Manufacturing</h3>
                    <p>Manufacturing is the base of any organization. It is impossible to function ....
                        <a routerLink="/manufacturing" class="read-more-btn">Read More</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon bg-cdf1d8">
                        <img src="assets/img/homepage_icon_img/Accreditation-Services.png" alt="Security">
                    </div> -->
                    <h3> Power & Steel</h3>
                    <p>As the growth in industrial production boosts demand for electricity ....
                        <a routerLink="/power-steel" class="read-more-btn">Read More</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon bg-c679e3">
                        <img src="assets/img/homepage_icon_img/Exam-Services1.png" alt="Workmen">
                    </div> -->
                    <h3> FMCG </h3>
                    <p>The FMCG Sector of India has witnessed significant growth in the past.This is ....
                        <a routerLink="/fmcg" class="read-more-btn">Read More</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon bg-eb6b3d">
                        <img src="assets/img/homepage_icon_img/labour1.png" alt="Supply">
                    </div> -->
                    <h3> Pharmaceutical & Healthcare</h3>
                    <p>A job in the pharmaceutical sector needs a lot of dedication and patience along with ....
                        <a routerLink="/pharmaceutical-healthcare" class="read-more-btn">Read More</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon">
                        <img src="assets/img/homepage_icon_img/staff.png" alt="Office">
                    </div> -->
                    <h3> Information Technology</h3>
                    <p>Information Technology is one of the most flourishing sectors in India ....
                        <a routerLink="/information-technology" class="read-more-btn">Read More</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon">
                        <img src="assets/img/homepage_icon_img/house.png" alt="House Keeping">
                    </div> -->
                    <h3> ITes & BPO</h3>
                    <p>Information Technology enabled Services is defined as outsourcing of processes that ....
                        <a routerLink="/it-bpo" class="read-more-btn">Read More</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon">
                        <img src="assets/img/homepage_icon_img/house.png" alt="House Keeping">
                    </div> -->
                    <h3>  Telecom </h3>
                    <p>At present, the Telecom Industry of India is flourishing. It is a fact that,....
                        <a routerLink="/telecom" class="read-more-btn">Read More</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon">
                        <img src="assets/img/homepage_icon_img/house.png" alt="House Keeping">
                    </div> -->
                    <h3>  Banking & Financial Services </h3>
                    <p>The Banking, Financial Services and Insurance sector in India has been ....
                        <a routerLink="/banking-financial-services" class="read-more-btn">Read More</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon">
                        <img src="assets/img/homepage_icon_img/house.png" alt="House Keeping">
                    </div> -->
                    <h3>  Education</h3>
                    <p>The number of academic institutes has increased exponentially in India. There is ....
                        <a routerLink="/education" class="read-more-btn">Read More</a>
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <!-- <div class="icon">
                        <img src="assets/img/homepage_icon_img/house.png" alt="House Keeping">
                    </div> -->
                    <h3> <strong>Retail & Hospitality</strong>  </h3>
                    <p>In the past few years, many national and international companies have ventured ....
                         <a routerLink="/retail-hospitality" class="read-more-btn">Read More</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Industry We Serve Area -->


<!-- Start Our Services Area -->
<div class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2> <span style="color: #027CD5;"> Our </span> Services</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <img src="assets/img/homepage_icon_img/Training.png" alt="Training">
                    </div>
                    <h3> Leadership Hiring</h3>
                    <p> <strong>Search</strong> top quality leaders (CEO / COO / CXO) for Corporate </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-f78acb">
                        <img src="assets/img/homepage_icon_img/Learning-Infrastructure.png" alt="Recruitment">
                    </div>
                    <h3>Recruitment of Engineers & Executives</h3>
                    <p><strong>Recruitment</strong>  of Engineers (IT / Non-IT) & Executives (HR / Finance / Sales & Marketing) across all levels</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-cdf1d8">
                        <img src="assets/img/homepage_icon_img/Accreditation-Services.png" alt="Security">
                    </div>
                    <h3>Security Service</h3>
                    <p><strong> Provide Security Services</strong>  to Corporate Houses, Plants, Commercial Establishments & Housing Societies</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-c679e3">
                        <img src="assets/img/homepage_icon_img/Exam-Services1.png" alt="Workmen">
                    </div>
                    <h3>Recruitment of Workmen</h3>
                    <p><strong>Recruitment</strong>  of critical category of workmen (Highly-skilled / Skilled / Semi-skilled) for varied industries</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-eb6b3d">
                        <img src="assets/img/homepage_icon_img/labour1.png" alt="Supply">
                    </div>
                    <h3>Labour Contractor</h3>
                    <p><strong> Supply</strong>  workmen on short / medium / long term basis to various industries</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <img src="assets/img/homepage_icon_img/staff.png" alt="Office">
                    </div>
                    <h3>Office / Facility Management Staff</h3>
                    <p><strong>Provide Office</strong> /Facility Management Staffs to various industries</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <img src="assets/img/homepage_icon_img/house.png" alt="House Keeping">
                    </div>
                    <h3>House Keeping Staff</h3>
                    <p><strong>Provide House Keeping Staffs </strong> to Corporate Houses, Plants, Commercial Establishments & Housing Societies </p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Our Services Area -->

<!-- Start Invoicing Area -->
<!-- <div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2>Easy Payment to borrow free get a better filling at home</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</p>
                    <a routerLink="/contact" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/invoicing-image/invoicing1.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing3.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing-image/invoicing4.png" class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/invoicing-image/main-pic.png" class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Invoicing Area -->

<!-- Start Fun Facts Area -->
<!-- <app-funfact></app-funfact> -->
<!-- End Fun Facts Area -->
<!-- behavior="alternate" "slide", "scroll" -->
<div class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2> <span style="color: #027CD5;"> Our </span> Clients</h2>
            <div class="bar"></div>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
            <marquee behavior="scroll" direction="left" scrollamount="5"> 
                <img src="assets/img/client/clients-name.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name2.jpg" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name3.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name4.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name5.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name6.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name7.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name8.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name9.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name10.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name11.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name12.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name13.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name14.png" width="120" height="80" alt="Natural" /> &nbsp;&nbsp;&nbsp;
                <img src="assets/img/client/clients-name15.png" width="120" height="80" alt="Natural" /> 
            </marquee>
        </div>
    </div>
</div>

<!-- Start Main Banner Area -->
<!-- <div class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2> <span style="color: #027CD5;"> Client </span> Testimonials</h2>
            <div class="bar"></div>
        </div>
    </div>
</div> -->

<!-- <div class="container"> -->
    <div class="section-title">
        <h2> <span style="color: #027CD5;"> Client </span> Testimonials</h2>
        <div class="bar"></div>
    </div>
<!-- </div> -->

<div class="home-area">
    <div class="container">
        <div class="home-slides owl-carousel owl-theme">
            <div class="banner-item">
                <div class="row align-items-center m-0">
                    <div class="col-lg-12 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h1>“The communication process with the Consultants of JJR Consultancy has 
                                always been very open and effective. The quality of work completed by the team is 
                                commendable.”</h1>
                                <br>
                            <p>Mr. Saksham Gupta</p>
                            <p style="font-size: 15px;">Bussiness Owner</p>
                            <!-- <a routerLink="/" class="btn btn-primary">Get Started</a> -->
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/banner-image/banner1.jpg" alt="banner-image">
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="banner-item">
                <div class="row align-items-center m-0">
                    <div class="col-lg-12 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h1>“The professional relationship with JJR Consultancy has 
                                proven to be beneficial to our organization and its 
                                business beyond our expectations.”</h1>
                                <br>
                            <p>Mrs. Prachi Goel</p>
                            <p style="font-size: 15px;">Security Agency Owner</p>
                            <!-- <a routerLink="/" class="btn btn-primary">Get Started</a> -->
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/banner-image/banner2.jpg" alt="banner-image">
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="banner-item">
                <div class="row align-items-center m-0">
                    <div class="col-lg-12 col-md-12 p-0">
                        <div class="banner-item-content">
                            <h1>“We look forward to a continued successful working relationship 
                                with JJR Consultancy and would look forward to recommend 
                                JJR Consultancy to others...”</h1>
                                <br>
                            <p>Mr. Somnath</p>
                            <p style="font-size: 15px;">Labour Contractor</p>
                            <!-- <a routerLink="/" class="btn btn-primary">Get Started</a> -->
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 col-md-12 p-0">
                        <div class="banner-item-image">
                            <img src="assets/img/banner-image/banner2.jpg" alt="banner-image">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <app-feedback></app-feedback> -->

<!-- <app-talk-button></app-talk-button> -->

<!-- <app-partner></app-partner> -->

<!-- <app-download></app-download> -->

<!-- Start Account Create Area -->
<!-- <app-account-button></app-account-button> -->
<!-- End Account Create Area -->